<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>
              ສິນຄ້າທັງໝົດ:
              <span class="primary-color">({{ count }})</span>
            </h2>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່"
              type="text"
              v-model="search"
              @keyup.enter="Search()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="products"
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          :disabled="TableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.amount="{ item }">
            <v-chip :color="getColor(item.amount)">{{ item.amount }}</v-chip>
          </template>
          <template v-slot:item.createdDate="{ item }">
            {{ moment(item.createdDate).format("DD-MM-YYYY") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2 primary-color"
              @click="OpenModalAdd(item)"
            >
              mdi-cart-arrow-down
            </v-icon>
          </template>
        </v-data-table>
        <br />
        <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchData()"
        ></Pagination>

        <!--Delete Modal-->
        <ModalAdd>
          <template @close="close">
            <v-card>
              <v-card-title>
                <span class="headline">Return Product</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <h3>{{ product.name }}</h3>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="ຈຳນວນ *"
                          required
                          v-model="qty"
                          :rules="productAmountRules"
                          type="number"
                          class="input-number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="selectedUser"
                          :items="users"
                          item-text="name"
                          item-value="id"
                          label="ຮັບຈາກ"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="start_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="return_date"
                              label="ວັນທີ"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="return_date"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="ລາຍລະອຽດ"
                          v-model="note"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="AddItem()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </template>
        </ModalAdd>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import return_warehouse from "@/mixins/warehouse/return_warehouse_product";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [return_warehouse],
  components: {
    Pagination,
  },
};
</script>

<style scoped>
/*@import "../../../../../public/scss/main.scss";*/
.customer {
  color: #000;
  font-size: 18px;
}
.invoice {
  font-size: 18px;
}
.primary-color {
  color: #00c1d2;
}
.text-field {
  border-color: #00c1d2;
  padding: 12px 8px 12px 8px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
}
</style>
