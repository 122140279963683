var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" ສິນຄ້າທັງໝົດ: "),_c('span',{staticClass:"primary-color"},[_vm._v("("+_vm._s(_vm.count)+")")])])]),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.Search()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.amount)}},[_vm._v(_vm._s(item.amount))])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.createdDate).format("DD-MM-YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 primary-color",attrs:{"small":""},on:{"click":function($event){return _vm.OpenModalAdd(item)}}},[_vm._v(" mdi-cart-arrow-down ")])]}}])}),_c('br'),(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e(),_c('ModalAdd',[[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Return Product")])]),_c('v-card-text',[_c('v-container',[_c('h3',[_vm._v(_vm._s(_vm.product.name))]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"input-number",attrs:{"label":"ຈຳນວນ *","required":"","rules":_vm.productAmountRules,"type":"number"},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"name","item-value":"id","label":"ຮັບຈາກ"},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.return_date),callback:function ($$v) {_vm.return_date=$$v},expression:"return_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_menu),callback:function ($$v) {_vm.start_menu=$$v},expression:"start_menu"}},[_c('v-date-picker',{model:{value:(_vm.return_date),callback:function ($$v) {_vm.return_date=$$v},expression:"return_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"ລາຍລະອຽດ"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.CloseModalAdd()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.AddItem()}}},[_vm._v(" Save ")])],1)],1)],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }