import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  data() {
    return {
      return_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start_menu: false,
      loading: false,
      TableLoading: false,
      products:[],
      users:[],
      selectedUser:'',
      productId: "",
      product: {},
      qty:1,
      note:'',
      count:0,

      //Pagination
      offset: 15,
      pagination: {},
      per_page: 100,
      oldVal: "",
      search:'',
      //Location
  // Service-Zone
      headers: [
        {
          text: "ລະຫັດສິນຄ້າ",
          align: "start",
          value: "barcode",
          sortable: false
        },
        {
          text: "ຊື່ສິນຄ້າ",
          align: "start",
          value: "name",
        },
        {
          text: "ປະເພດ",
          align: "start",
          value: "category",sortable: false
        },
        { text: "ຈຳນວນ", value: "amount" },
        { text: "ຫົວໜ່ວຍ", value: "unit",sortable: false },
        // { text: "ລາຍລະອຽດ", value: "description" },
        // { text: "ວັນທີ", value: "createdDate" },
        { text: "Actions", value: "actions", sortable: false ,  align: "center",},
      ],
      productAmountRules: [
        (v) => !!v || "Amount Code is required",
      ],
      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
    };
  },
  methods: {
    OpenModalAdd(item) {
     if(item != null){
      this.product = item;
      this.$store.commit("modalAdd_State", true);
     }
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },
    SubmitItem() {
      this.product.return_from_id = this.selectedUser;
      this.product.return_date = this.return_date;
      this.product.product_id = this.product.id;
      this.product.amount = this.qty;
      this.product.note = this.note;
      this.loading = true;
      this.$admin
        .post("warehouse/return-product", this.product)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.CloseModalAdd();
              this.note = '';
              this.qty = 0,
              this.fetchData();
              this.reset();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          this.fetchData();
          if (error.response.status == 422) {
            var obj = error.response.data.errors;
            for (let [key, product] of Object.entries(obj)) {
              this.server_errors[key] = product[0];
            }
          }
        });
    },

    CloseModalAdd() {
      this.note = '';
      this.qty = 0,
      this.reset();
      this.fetchData();
      this.$store.commit("modalAdd_State", false);
    },

    fetchData() {
      this.TableLoading = true;
      this.$admin.get('warehouse/products',{
        params:{
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search
        }
    }).then(res => {
        setTimeout(() => {
          this.products = res.data.products.data;
          this.pagination = res.data.products;
          this.count = res.data.products.total
          this.TableLoading =false;
        }, 500);
      }).catch(() => {
        this.TableLoading =false;
        this.$store.commit("Toast_State",this.toast_error);
      });
      this.$admin.get('list-department').then(res => {
        setTimeout(() => {
          this.users = res.data.department;
          this.TableLoading =false;
        }, 100);
      }).catch(() => {
        this.TableLoading =false;
        this.$store.commit("Toast_State",this.toast_error);
      });
    },

    reset() {
      this.$refs.form.reset();
    },

///Search
Search() {
  GetOldValueOnInput(this);
},

    //Get Round color
    getColor(amount) {
      if (amount == 0) return "error";
      else return "success";
    },
  },
  created() {
    this.fetchData();
  },
};
